import styled from 'styled-components'

const Container = styled.div`
  width: 100%;

  .intro {
    position: relative;
    width: 100%;
    display: flex;

    .image {
      overflow: hidden;
      position: relative;
      width: 50%;
      height: 98vh;
      background-position: center;
      background-size: cover;
      cursor: pointer;

      a {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 11;
      }

      h3 {
        position: absolute;
        bottom: ${({ theme }) => theme.spacingM};
        left: 50%;
        transform: translateX(-50%);
        font-size: 3.5rem;
        line-height: 3.5rem;
        font-weight: ${({ theme }) => theme.fontMedium};
        text-align: center;
        text-transform: uppercase;
        padding: 0.9rem 3rem 0.5rem 3rem;
        border-radius: 25px;
        background: transparent;
        opacity: 0;
        transition: opacity 0.25s ease;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: radial-gradient(
          45% 58%,
          transparent 60%,
          ${({ theme }) => theme.blue} 10%
        );
        transform: scale(3);
        transition: all 0.25s ease;
      }

      &.gold-effect :after {
        background: radial-gradient(
          45% 58%,
          transparent 60%,
          ${({ theme }) => theme.gold} 10%
        );
      }

      &:hover {
        h3 {
          opacity: 1;
        }
        &:after {
          transform: scale(1);
        }
      }
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: ${({ theme }) => theme.fontFamilySecondary};
      font-size: 7rem;
      line-height: 7rem;
      font-weight: ${({ theme }) => theme.fontBold};
      text-align: center;
      text-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
    }

    .arrow {
      position: absolute;
      bottom: -3.5rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 4.9rem;
      height: 9rem;
      padding-top: ${({ theme }) => theme.spacingXXS};
      display: flex;
      justify-content: center;
      border-radius: 25px;
      cursor: pointer;
      z-index: 11;

      svg {
        width: 2.5rem;
        height: auto;
      }
    }
  }

  .content {
    padding: ${({ theme }) => theme.spacingL} 0 0;
  }

  .about {
    padding: ${({ theme }) => theme.spacingL};

    &__content {
      width: 50%;

      .title {
        max-width: 65rem;
      }
      .text {
        max-width: 65rem;
        margin-top: ${({ theme }) => theme.spacingM};
      }
      .cta {
        margin-top: ${({ theme }) => theme.spacingM};
      }
    }

    &__video {
      width: 50%;
      text-align: center;

      iframe {
        width: 100%;
        height: calc(50vw / 1.77);
        max-width: 75rem;
        max-height: calc(75rem / 1.77);
      }
    }
  }

  .rounded-section {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    border-top-right-radius: 35rem;
    border-bottom-left-radius: 35rem;
  }

  .team {
    margin-top: ${({ theme }) => theme.spacingXL};
    padding: ${({ theme }) => `${theme.spacingS} ${theme.spacingM} 0`};
    border-bottom: 1px solid ${({ theme }) => theme.blue};

    &__image {
      max-width: 100%;
      width: 50%;
    }

    &__content {
      width: 50%;
      padding: 0 ${({ theme }) => theme.spacingS} 0
        ${({ theme }) => theme.spacingM};

      .mini-title {
        text-align: left;
      }

      .title {
        margin-top: ${({ theme }) => theme.spacingS};
      }
      .cta {
        margin-top: ${({ theme }) => theme.spacingM};
      }
    }
  }

  .blog-snippet {
    padding: ${({ theme }) =>
      `${theme.spacingL} ${theme.spacingM} ${theme.spacingXL} ${theme.spacingM}`};
  }

  .vertical-dots
  {
      right:10px;
      list-style: none;
      display: block;
      position: absolute;
      top: 40%;
      margin-top: -10px;
      text-align: right;
  }
  .vertical-dots li
  {
      position: relative;
      width: 20px;
      height: 20px;
      cursor: pointer;
  }
    .vertical-dots li button
    {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
    }
    .vertical-dots li button:hover,
    .vertical-dots li button:focus
    {
        outline: none;
    }
    .vertical-dots li button:hover:before,
    .vertical-dots li button:focus:before
    {
        opacity: 1;
    }
    .vertical-dots li button:before
    {
        font-family: 'slick';
        font-size: 6px;
        line-height: 20px;

        position: absolute;
        top: 0;
        left: 0;

        width: 20px;
        height: 20px;

        content: '•';
        text-align: center;

        opacity: .25;
        color: white;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .vertical-dots li.slick-active button:before
    {
        opacity: .75;
        color: white;
    }

    .object-fit{
      object-fit: cover;
      object-position: center;

    }

    .full-width{
      width: 100% !important;
    }

  @media screen and (max-width: 1000px) {
    .banner {
      display: none !important;
    }
    .intro {
      padding-top: 15vh;
      flex-direction: column;

      h1 {
        display: none;
      }

      .image {
        width: 100%;
        height: 42.5vh;

        &:after {
          display: none;
        }

        h3 {
          font-size: 1.7rem;
          opacity: 1;

          &.bg-blue {
            background: ${({ theme }) => theme.blue};
          }
          &.bg-gold {
            background: ${({ theme }) => theme.gold};
          }
        }
      }

      .banner {
        position: relative;
        top: 3rem;
        padding-top: ${({ theme }) => theme.spacingXL};
        min-height: 65rem;
        width: 150vw;
        margin-left: -25vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: ${({ theme }) => theme.spacingM};
        border-radius: 30%;
        z-index: 1;

        h2 {
          font-family: ${({ theme }) => theme.fontFamilySecondary};
          font-weight: ${({ theme }) => theme.fontRegular};
          font-size: 3.5rem;
          line-height: 4.5rem;
          text-align: center;
        }
      }

      .arrow {
        background: transparent;
        bottom: -8rem;
      }
    }

    .about {
      flex-direction: column;
      gap: ${({ theme }) => theme.spacingM};
      padding-bottom: 0;

      &__content,
      &__video {
        width: 100%;
        text-align: center;

        .title,
        .text {
          max-width: 75rem;
          margin-left: auto;
          margin-right: auto;
        }

        iframe {
          height: calc(70vw / 1.77);
        }
      }
    }

    .team {
      flex-direction: column-reverse;
      padding-top: ${({ theme }) => theme.spacingL};

      &__content {
        width: 85%;
        text-align: center;
        padding-left: ${({ theme }) => theme.spacingS};

        .mini-title {
          text-align: center;
        }
      }

      &__image {
        width: 75%;
        margin-top: ${({ theme }) => theme.spacingL};
      }
    }

    .blog-snippet .image {
      width: unset;
      margin-bottom: ${({ theme }) => theme.spacingL};
    }
  }

  @media screen and (max-width: 500px) {
    .about {
      padding-left: ${({ theme }) => theme.spacingS};
      padding-right: ${({ theme }) => theme.spacingS};

      iframe {
        height: calc(90vw / 1.77);
      }
    }
  }
`

export const Styled = {
  Container,
}
