import React, { useState, useEffect } from 'react'
import { Title } from './Title'
import { Text } from './Text'
import styled, { keyframes } from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { StaticQuery, graphql } from 'gatsby'

import { ConsentGtag } from '../utils/consentGtag'

export const NewsletterModal = ({ setIsOpen }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState()
  const [alreadySubscribed, setAlreadySubscribed] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setAlreadySubscribed(false)
    ConsentGtag()
    addToMailchimp(email, { FNAME: name }).then(({ result, msg }) => {
      setSuccess(result === 'success')
      setName('')
      setEmail('')
      if (result === 'success') {
        typeof window !== 'undefined' &&
          typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'form_newsletter', {
            form: 'Formulário de Newsletter'
          })
      } else if (msg && msg.includes('is already subscribed')) {
        setAlreadySubscribed(true)
      }
    })
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          miscJson {
            newsletterModal {
              background
              title
              text
              nameInputLabel
              emailInputLabel
              btn
              successTitle
              successText
              errorTitle
              errorText
              alreadySubscribedTitle
              alreadySubscribedText
            }
          }
        }
      `}
      render={({ miscJson: content }) => (
        <>
          <StyledModal>
            <span className="close" onClick={() => setIsOpen(false)}>
              x
            </span>

            <div className="image">
              <div
                className="background"
                style={{
                  backgroundImage: `url(${content.newsletterModal.background})`,
                }}
              >


                {success === true ? (
                  <div className="content feedback newsletter-form-success">
                    <Title text={content.newsletterModal.successTitle} />
                    <Text text={content.newsletterModal.successText} />
                  </div>
                ) : success === false ? (
                  alreadySubscribed ? (
                    <div className="content feedback newsletter-form-error">
                      <Title
                        text={content.newsletterModal.alreadySubscribedTitle}
                      />
                      <Text text={content.newsletterModal.alreadySubscribedText} />
                    </div>
                  ) : (
                    <div className="content feedback newsletter-form-error">
                      <Title text={content.newsletterModal.errorTitle} />
                      <Text text={content.newsletterModal.errorText} />
                    </div>
                  )
                ) : (
                  <div className="content content-show flex-center" >
                    <div>
                      <Title text={content.newsletterModal.title} />
                      <Text text={content.newsletterModal.text} />

                      <form onSubmit={handleSubmit}>
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder={content.newsletterModal.nameInputLabel}
                          required
                        />
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder={content.newsletterModal.emailInputLabel}
                          required
                        />
                        <button className="blue">
                          {content.newsletterModal.btn}
                        </button>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyledModal>

          <StyledOverlay onClick={() => setIsOpen(false)} />
        </>
      )}
    />
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const StyledModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  max-width: 50vw;
  min-height: 30vw;
  display: flex;
  background: transparent;
  border-radius: 0;
  opacity: 0;
  z-index: 111;
  box-shadow: 5px 4px 6px rgb(0 0 0 / 16%);
  overflow: hidden;
  animation: 0.3s ${fadeIn} ease forwards;

  .close {
    position: absolute;
    top: 4rem;
    right: 5rem;
    color: ${({ theme }) => theme.blue};
    font-size: 2.5rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.2s;

    &:hover{
      color: ${({ theme }) => theme.gold};
    }
  }

  .image {
    width: 100%;
    height: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: hidden;

    .background {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: 10% 50%;
    }
  }

  .content {
    width: 40% !important;
    margin-right: 8% !important;
    height: 100%;
    margin-left: auto;

    &.feedback {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .title {
      color: ${({ theme }) => theme.blue};
      font-size: 4.4rem;
      line-height: 4rem;
      margin-bottom: 3rem;
    }

    .text {
      color: ${({ theme }) => theme.gold};
      margin-top: 1rem;
      margin-bottom: 3rem;
      line-height: 2.5rem;
    }

    form {
      margin-top: 1rem
    }

    input {
      width: 100%;
      color: ${({ theme }) => theme.blue};
      font-size: 1.4rem;
      padding: 1rem 1.5rem;
      border: 1px solid ${({ theme }) => theme.blue};
      border-radius: 25rem;

      &:not(:first-child) {
        margin-top: 2rem;
      }

      ::placeholder {
        color: ${({ theme }) => theme.blue};
        text-transform: uppercase;
      }
    }

    button {
      font-size: 1.8rem;
      font-weight: ${({ theme }) => theme.fontBold};
      letter-spacing: 0.03rem;
      background: transparent;
      text-transform: uppercase;
      text-decoration: underline;
      padding: 0 ${({ theme }) => theme.spacingXS} 0 0;
      margin-top: 3rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1000px) {
    max-width: 90%;
    width: 90%;
    min-height: 90vw;
    .close{
      top: 3vw;
      right: 3vw;
      font-size: 2.3rem;
    }
    .image {
  
      .background {
        background-position: 50% 50%;
      }
    }
    .content{
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
      .title{
        font-size: 3rem;
        line-height:  1;
      }
    }
  }

`

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
`
