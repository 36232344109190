import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Title } from '../components/Title'
import { Text } from '../components/Text'
import { CTA } from '../components/CTA'
import { BlogSnippet } from '../components/BlogSnippet'
import { FormAndMap } from '../components/FormAndMap'
import { NewsletterModal } from '../components/NewsletterModal'
import { Styled } from '../styles/index.styles'
import { parseBlogPosts } from '../utils/helpers'
import ArrowIcon from '../assets/images/arrow.svg'
import { Link, graphql } from 'gatsby'


import Slider from "react-slick";

const IndexPage = ({
  data: {
    homeJson: content,
    allMarkdownRemark: { edges },
  }, location
}) => {
  const [showNewsletterModal, setShowNewsletterModal] = useState(false)

  const blogPost = parseBlogPosts(edges)[0]

  const showModal = () => setShowNewsletterModal(true)

  useEffect(() => {
    setTimeout(showModal, 5000)
    return () => clearTimeout(showModal)
  }, [])

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'vertical-dots'
  };

  return (
    <Layout isHomePage location={location}>
      <SEO description={content.seoDescription} />
      <Styled.Container>
        <section className="intro">
          <div className="banner bg-blue mbl">
            <h2
              className="white"
              dangerouslySetInnerHTML={{ __html: content.mainTitle }}
            />
            <CTA color="gold" text={content.cta} url={content.ctaUrl} />
          </div>
          <div
            className="image"
            style={{ backgroundImage: `url(${content.mainImage1})` }}
          >
            <Link to={content.mainImage1Url}>
              <h3 className="bg-blue white">{content.sectionTitle1}</h3>
            </Link>
          </div>
          <div
            className="image gold-effect"
            style={{ backgroundImage: `url(${content.mainImage2})` }}
          >
            <Link to={content.mainImage2Url}>
              <h3 className="bg-gold white">{content.sectionTitle2}</h3>
            </Link>
          </div>
          <h1
            className="white"
            dangerouslySetInnerHTML={{ __html: content.mainTitle }}
          />
          <div className="arrow bg-gold">
            <Link to="/#about">
              <ArrowIcon />
            </Link>
          </div>
        </section>

        <section className="content bg-gold">
          <div id="about" className="about flex-center">
            <div className="about__content">
              <Title text={content.aboutTitle} />
              <Text text={content.aboutText} />
              <CTA
                text={content.aboutButton}
                url={content.aboutButtonUrl}
                className="desktop to-white"
              />
            </div>
            <div className="about__video">
              <iframe
                src={content.aboutVideo}
                title={content.aboutTitle}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </div>
            <CTA
              text={content.aboutButton}
              url={content.aboutButtonUrl}
              className="mbl to-white"
            />
          </div>

          <div
            className="rounded-section"
            style={{ backgroundImage: `url(${content.backgroundImg})` }}
          >
            <div className="team flex-center">
              <img
                src={content.teamImage}
                alt={content.teamTitle}
                className="team__image"
              />
              <div className="team__content">
                <h3 className="mini-title">{content.teamTitle}</h3>
                <Title text={content.teamText} dark />
                <CTA text={content.teamButton} url={content.teamButtonUrl} />
              </div>
            </div>

            <BlogSnippet miniTitle="Blogue" post={blogPost} />
          </div>
          <div id="marcacao">
            <FormAndMap white={false} />
          </div>
        </section>
      </Styled.Container>

      {showNewsletterModal && (
        <NewsletterModal
          isOpen={showNewsletterModal}
          setIsOpen={setShowNewsletterModal}
        />
      )}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            path
            title
            miniTitle
            img
            status
            description
          }
        }
      }
    }
    homeJson {
      seoDescription
      mainTitle
      mainImage1
      mainImage1Url
      sectionTitle1
      mainImage2
      mainImage2Url
      backgroundImg
      sectionTitle2
      aboutTitle
      aboutText
      aboutVideo
      aboutButton
      aboutButtonUrl
      teamTitle
      teamText
      teamImage
      teamButton
      teamButtonUrl
      blogTitle
      cta
      ctaUrl
    }
  }
`
